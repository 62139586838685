import {
    $, addAction, doAction, removeAction, READY,
} from '@situation/setdesign.util';

addAction(READY, () => {
    const id = 'agenda-event-cle-modal';
    const $cleModal = $(`#${id}`);
    const hideModal = 'hideModal';
    if ($cleModal.length && !window.Cookies.get(id)) {
        $(document).on('gform_confirmation_loaded', () => {
            addAction(hideModal, ($modal) => {
                removeAction('key.esc', () => {
                    doAction(hideModal, $('.modal--open'));
                });

                $('body').removeClass('lock-scroll');
                $modal.removeClass('modal--open');
                doAction('focusStateReset');
            });

            doAction(hideModal, $cleModal);
            window.Cookies.set(id, true);
        });

        removeAction(hideModal);
        doAction('showModal', $cleModal);
    }
});
