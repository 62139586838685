import {
    $, addAction, addFilter, INIT,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    const $footer = $('.footer-nav');
    if (!$footer.length) {
        return;
    }

    addFilter('css-vars/register', (styles) => {
        styles['footer-height'] = () => `${$footer.outerHeight(true)}px`;
        return styles;
    });
});
