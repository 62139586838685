import { addFilter } from '@situation/setdesign.util';

addFilter('content-slider/owl-settings', (options, $instance) => {
    if ($instance.hasClass('hero-slider')) {
        return Object.assign(options, {
            items: 1, loop: true, margin: 32, dots: false,
        });
    }
    if ($instance.hasClass('featured-sessions')) {
        return Object.assign(options, {
            items: 2,
            loop: true,
            margin: 32,
            dots: false,
            responsive: { 0: { items: 1 }, 768: { items: 2 } },
        });
    }
    return options;
});
